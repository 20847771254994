.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			//float:left;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			font: $base-font-family;
			.file-extension {
				padding:3px 0 0; //$vert-space/5 $horz-space/4 $vert-space/4 0;
				text-transform:uppercase;
				font-size:1em;
        line-height:1;
				//text-align:center;
				font-weight:bold;
				//width: 70px;
    		float: left;
				margin-right: 20px;
			
				&:after {
					@include attachment-underline-default();
				}
			}
			.file-size {
        padding: 0;
				font-size:0.889rem;			
				//text-align:center;
				color:$secondary;
        //border:$secondary-border;
        //border-top:none;
			}
		}
		
		.file-name {
      overflow:hidden;
      font-size: 1.222rem;
      font-family: $title-font-family;
			color: $primary;
			/* display: block; */
    	clear: both;
		}
		
		&:hover {
			.file-name {
				color: $action-hover;
			}
			.file-extension {
				color: $action-hover;
				
				&:after {
					@include attachment-underline-default-hover();
				}
			}
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:none;
      color: $docx_color;
			
			&:after {
      	background:$docx_color;
			}
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:none;
      color:$xlsx_color;
			
			&:after {
      	background:$xlsx_color;
			}
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:none;
      color:$pptx_color;
			
			&:after {
      	background:$pptx_color;
			}
    }
  }
  .file-type-pdf {
    .file-extension {
      background:none;
      color:$pdf_color;
			
			&:after {
      	background:$pdf_color;
			}
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:none;
      color:$txt_color;
			
			&:after {
      	background:$txt_color;
			}
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:none;
      color:$jpg_color;
			
			&:after {
      	background:$jpg_color;
			}
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:none;
      color:$zip_color;
			
			&:after {
      	background:$zip_color;
			}
    }
  }
} 

.section-row.palette-highlight,
.section-row.palette-alt-2 {
	.view-attachments {
		.attachment-link {
			
			.file-name {
				color: $primary;
			}
			
			.file-extension {
				color: $primary;

				&:after {
					@include attachment-underline-alt();
				}
			}
			
			&:hover {
				.file-name {
					color: white;
				}

				.file-extension {
					color: white;

					&:after {
						@include attachment-underline-alt-hover();	
					}
				}
			}
		}
	}
}
