.page-user {

	#content > h1 {
		display: none;
	}

	.card-suffix {
		display: inline;
	}

  .card-profile {

    .label {
      font:$card-title-font;
      text-indent: 0;
      margin: 15px 0;
    }
    .card-profile-image {
      margin-bottom: $vert-space;
      text-align: center;

      .card-gsa-user-photo img {
        max-width: 360px;
        width: 100%;
      }

      @include media($narrow) {
        float: left;
        max-width: 240px;
        margin-right: $horz-space*2;
      }
    }

    .card-profile-details {
      overflow: hidden;

      .group-member-name > div {
        display: inline-block;
      }

      .group-member-job {
        margin-bottom: $vert-space;
        .card-job-title {
          font-size: 1.333rem;
          color: $secondary;
        }
        .card-gsa-institution {
          color: $secondary;
          font-size: 0.889rem;
        }
      }

      .card-profile-contact {
        margin-top: $vert-space;
        .card-mail {
          margin-bottom: $vert-space;
        }
      }

      .card-profile-additional {
        > div {
          margin-bottom: $vert-space;
        }
        .card-gsa-area-of-expertise,
        .card-gsa-career-stage,
        .card-gsa-user-country,
        .card-gsa-who-region,
        .card-gsa-countries,
        .card-gsa-group {
          span {
            display: block;
          }
        }
      }
    }
  }

  &.role-authenticated-user {
    .text-format-wrapper {
      .filter-wrapper {display: block;}
    }
  }
}




//Hide messages on account-confirmation
.page-node-334 {
  section#messages {
    display: none;
  }
}

.card-profile {
  .card-links {
    display: inline-block;
    margin: 0 10px 10px 0;
    text-indent: -9999px;

    a {
      background: $card-links-action-bg $ico-web no-repeat 50% 50% / 19px 19px;
      border-radius: $card-links-border-radius;
      width: $card-links-width;
      height: $card-links-height;
      display:block;
      margin: 0 5px;
      float: right;

      &:hover {
        opacity: $card-links-hover-opacity;
        border-radius: $card-links-hover-border-radius;
        background: $card-links-hover-bg $ico-web-over no-repeat 50% 50% / 19px 19px;
      }
    }

    /* newsletter */
    .newsletter a,
    a[href^="http://eepurl.com"] {
      background: $ico-newsletter no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
      &:hover {
        background: $ico-newsletter-over no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
      }
    }

    /* linkedin */
    .linkedin a,
    a[href^="https://www.linkedin.com"],
    a[href^="http://www.linkedin.com"],
    a[href^="https://linkedin.com"],
    a[href^="http://linkedin.com"] {
      background: $ico-linkedin no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
      &:hover {
        background: $ico-linkedin-over no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
      }
    }

    /* twitter */
    .twitter a,
    a[href^="https://www.twitter.com"],
    a[href^="http://www.twitter.com"],
    a[href^="https://twitter.com"],
    a[href^="http://twitter.com"] {
      background: $ico-twitter no-repeat scroll 50% 50% / 20px 20px $card-links-action-bg;
      &:hover {
        background: $ico-twitter-over no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
      }
    }

    /* facebook */
    .facebook a,
    a[href^="https://www.facebook.com"],
    a[href^="http://www.facebook.com"],
    a[href^="https://facebook.com"],
    a[href^="http://facebook.com"] {
      background: $ico-facebook no-repeat scroll 50% 50% / 18px 18px $card-links-action-bg;
      &:hover {
        background: $ico-facebook-over no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
      }
    }

    /* google+ */
    .g-plus a,
    a[href^="https://www.plus.google.com"],
    a[href^="http://www.plus.google.com"],
    a[href^="https://plus.google.com"],
    a[href^="http://plus.google.com"] {
      background:$ico-gplus no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
      &:hover {
        background: $ico-gplus-over no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
      }
    }

    /* Institutional profile */
    .institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution no-repeat 50% 50% / 19px 19px $card-links-action-bg;
      &:hover {
        background: $ico-institution-over no-repeat 50% 50% / 19px 19px $card-links-hover-bg;
      }
    }

    /* 		Research Gate */
    .research-gate a,
    a[href*="researchgate.net"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
      background: $ico-researchgate no-repeat 50% 50% / 19px 18px $card-links-action-bg;
      &:hover {
        background: $ico-researchgate-over no-repeat 50% 50% / 19px 18px $card-links-hover-bg;
      }
    }

    /* Google Scholar */
    .g-scholar a,
    a[href*="scholar.google"] {
      background: $ico-gscholar no-repeat 50% 50% / 17px 19px $card-links-action-bg;

      &:hover {
        background: $ico-gscholar-over no-repeat 50% 50% / 17px 19px $card-links-hover-bg;
      }
    }

    /* orcid */
    .orcid a,
    a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid no-repeat 50% 50% / 18px 18px $card-links-action-bg;
      &:hover {
        background: $ico-orcid-over no-repeat 50% 50% / 18px 18px $card-links-hover-bg;
      }
    }

    /* YouTube */
    .youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background:$ico-youtube no-repeat 50% 50% / 19px 13px $card-links-action-bg;

      &:hover {
        background: $ico-youtube-over no-repeat 50% 50% / 19px 13px $card-links-hover-bg;
      }
    }

    /* flickr */
    .flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr no-repeat 50% 50% / 18px 14px $card-links-action-bg;

      &:hover {
        background: $ico-flickr-over no-repeat 50% 50% / 18px 14px $card-links-hover-bg;
      }
    }

    /* instagram */
    .instagram a,
    a[href^="https://www.instagram.com"],
    a[href^="http://www.instagram.com"],
    a[href^="https://instagram.com"],
    a[href^="http://instagram.com"] {
      background:$ico-instagram no-repeat 50% 50% / 18px 18px $card-links-action-bg;

      &:hover {
        background: $ico-instagram-over no-repeat 50% 50% / 18px 18px $card-links-hover-bg;
      }
    }
  }
}

// user registration

.field-name-field-gsa-bio {
  label {
    margin-bottom: 40px;
  }
  .text-format-wrapper {
    position: relative;
    .description {
      position: absolute;
      top: 20px;
    }
  }
}

.field-name-field-links {
  .description {
    margin:10px 0;
  }
  table {
    th {
      padding: 5px;
      background-color: #f8f8f8;
      border: 1px solid #d1d1d1;
      label {
        margin-left: 20px;
      }
    }
    td {
      width: 100%;
      padding: 10px 20px 0px;
      border-right: 1px solid #d1d1d1;
    }
  }
  input.multiple-fields-remove-button {
    max-height: 40px;
    padding: 5px 15px;
    margin-left: 30px;
  }
  .form-type-link-field {
    align-items: center;
    display: flex;
    .link-field-subrow {
      display: flex;
      flex-direction: column;
      display: flex;
      .link-field-column {
        width: 100%;
      }
      .form-item {
        display: flex;
        label {
          margin-right: 15px;
          margin-top: 7px;
        }
      }
    }
    input[type="text"] {
      background-color:#EAEFF8 ;
    }
    .description {
      display: none;
    }
  }
}

.field-name-field-gsa-display{
  margin-top: 30px;
}
