
@mixin forum-comment-wrapper() {
  .comment {
    padding: $vert-space $horz-space;
    background-color: white;
    margin-bottom: $vert-space*1.5;

    .comment-header {
      .new {
        display: inline-block;
        color: $princeton_orange;
        text-transform: uppercase;
        float: right;
        font-size: 0.778rem;
        font-weight: bold;
        &:after {
          @include node-title-underline-default;
          margin-top:0;
          width: 100%;
          background: $princeton_orange;
        }
      }
      .submitted {
        display: inline-block;
        font-size: 0.889rem;
        margin-bottom: $vert-space;
      }

      .new + .submitted {
        width: calc(100% - 40px);
      }
    }

    .links li {
      display: inline-block;
      margin-right: 10px;

      a {
        @include forum-button-link-alt();
      }
    }

    .card-body .label {
      display: none;
    }
  }

  & > .indented {
    margin-top: -$vert-space;
    margin-bottom: $vert-space*1.5;
    .comment {
      margin-bottom: $vert-space/2;
    }
    .indented {
      margin-left: 0;
    }
  }
}

// FORUM PAGES - TABLES
.page-forum {

  h1#page-title {
    text-transform:none;
		&:after {
			@include node-title-underline-default;
			margin-top:5px;
		}
	}
}

.page-forum .action-links,
.node-type-forum .links,
.page-comment .links {
  list-style: none;
  padding: 0;

  a {
    border-radius: $button-border-radius;
    border: none;
    cursor: pointer;
    display: inline-block;
    font: $button-link-font;
    padding: 10px 20px;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    @include forum-button-link-default();
  }

  .comment-add {
    margin-bottom: $vert-space*2;
  }
}

// FORUM OVERRIDEN
#forum {
  td.created,
  td.posts,
  td.topics,
  td.last-reply,
  td.replies,
  td.pager {
    white-space: normal;
  }

  td.forum,
  td.last-reply,
  td.title {
    width: 50%;
  }

  td.topics,
  td.replies {
    a {
      padding: 0px 5px;
      margin-top: 5px;
      display: inline-block;
      clear: both;
      @include forum-button-link-default();
    }
  }

  table {
    max-width: 1000px;

    thead {
      th {
        font-size: 1.222rem;
        &.active {
          background-color: $tea_green;
        }
        a,
        a:hover {
        color: $primary;
        display: flex;
        align-items: center;

          &:after {
            content: "";
            display: inline-block;
            height: 26px;
            width: 13px;
            margin-left: 10px;
            background: no-repeat $forum-arrows 50% 50% / auto 26px transparent;
          }
        }

        &.active {
          a,
          a:hover {
            &:after {
              display: none;
            }
            img {
              margin-left: 5px;

              &[src*="arrow-asc.png"] {
                content:$forum-arrow-asc;
              }
              &[src*="arrow-desc.png"] {
                content:$forum-arrow-desc;
              }
            }
          }
        }
      }
    }
  }
}



// TOPIC NODE
.node-type-forum {
  .topic-content-wrapper {
    padding: $vert-space $horz-space;
    background-color: $azureish_white;
    .posted {
      font-size: 0.889rem;
      margin-bottom: $vert-space;
    }
    .card-taxonomy-forums {
      margin-bottom: $vert-space;

      .label {
        display: inline-block;
      }
    }
  }

  .comment-form-wrapper {
    padding: $vert-space $horz-space;
    background-color: $azureish_white;

    .title.comment-form {
      font-size: 1.222rem;
    }

    .form-actions.form-wrapper input {
      display: inline-block;
      margin-right: 10px;
    }

    .form-item-subject {
      display: none;
    }
  }

  .comment-wrapper {
    @include forum-comment-wrapper();
  }

  .block-subscriptions-ui {
    background-color: $azureish_white;

    & > h2 {
      display: none;
    }

    .form-item-subject {
      display: none;
    }

    #subscribe {
      border: none;
      margin: 0;
      padding: 0;

      &.collapsible.collapse-processed.collapsed {
        height: auto;

        .fieldset-legend {
          border-bottom: none;
          background: no-repeat $forum-single-arrow-right 120px 50% / 14px auto transparent;
        }
      }

      & > legend {
        width: 100%;

        .fieldset-legend {
          padding: $vert-space $horz-space;
          border-bottom: 1px solid $ocean_blue_boat;
          background: no-repeat $forum-single-arrow-down 120px 50% / 14px auto transparent;

          a {
            font-size: 1.222rem;
            color: $primary;
            //display: none;
          }
        }
      }

      & > .fieldset-wrapper {
        padding: $vert-space $horz-space;
      }

      .highcharttable-region {
        display: none;
      }

      table {
        thead {
          display: none;
        }

        thead, tbody, td, tr.even, tr.odd {
          border: none;
          background: transparent;
          padding-bottom: 0;
        }
      }
    }
  }
}

// PAGE COMMENTS
.page-comment,
.page-node-add-forum {
  h1#page-title .placeholder,
  .comment-form .form-item-subject {
    display: none;
  }

  h1#page-title {
    text-transform:none;
		&:after {
			@include node-title-underline-default;
			margin-top:5px;
		}
	}

  @include forum-comment-wrapper();

  .comment-form,
  .node-form {
    padding: $vert-space $horz-space;
    background-color: $azureish_white;

    .form-actions.form-wrapper input {
      display: inline-block;
      margin-right: 10px;
    }

    .form-item-subject {
      display: none;
    }
  }
}

.comment_forbidden {
  display: none;
}
