main .side-row .view-display-id-authors {
	.card {
		background-color: transparent;
		
		.card-text {
			background-color: $card-dark-bg-color;
		}
	}
		
	@media (max-width:720px), (min-width:960px) {
		.card.card-with-image {
			display: flex;
			flex-direction: row;

			.card-photo {
				align-self: flex-start;
				width: 30%;
				max-width: 160px;
				min-width: 80px; 
			}
			.card-text {
				margin-top: 20px;
				margin-right: 0;
				margin-left: 0;
				align-self: flex-start;
				width: 70%;
			}
		}
	}

	@media (max-width:720px) {
		.card-text {
			margin-top: 20px;
			margin-right: 0;
			margin-left: 0;
			align-self: flex-start;
			width: 70%;
		}
	}
}