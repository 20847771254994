// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#site-header {
	.block-menu-block {
		display: none;
	}
	.main-nav-wrapper,
	.block-menu-block {
		
		@media (min-width:880px) and (max-width:1150px) {
			//min-height:48px;
		}

		@include media(1150px) {
			//min-height:48px;
		}
	}

	// Next two media queries override the SM breakpoints to accommodate a wide menu
//	@media (min-width:880px) and (max-width:1030px) {
//		#main-menu-state:not(:checked) ~ ul.menu {
//			display: none;
//		}
//	}
	//@media (min-width: 1030px) {
	//	#main-menu-state:not(:checked) ~ ul.menu {
	//		display: block;
	//	}
	//}
}

// MENU STYLES 
#site-header {
	@include media(880px) {
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
		}
	}
	.block-menu-block {
		clear:right;
		position: relative;
		
		.main-menu-btn {
////			@media(min-width: 880px) and (max-width:1029px) {
////				top: 0;
////				//position: relative;
////
////
////				position: relative;
////				display: inline-block;
////				//width: 86px;
////				//height: 100px;
////				text-indent: -999em;
////				//margin: -100px 0px 0px 0px;
////
////
////			}
////			@media(min-width :1030px) {
////				top: -99999;
////				position: absolute;
////			}
			
			float:right;
			width:$nav-button-width;
			height:$nav-button-height;
			text-indent:-999em;
			margin: -70px 0px 0px 0px;
						
			.main-menu-btn-icon {
 				left:13px;
				height:$nav-button-bar-height;
				width:$nav-button-bar-width;
				background-color:$nav-button-bar-bg-color;
				border-radius:$nav-button-bar-border-radius;
				margin-top:-2px;
				
				&:before {
					top:-14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
				
				&:after {
					top:14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
			} 
		}
		
		ul.sm {
			background: none; 
			clear:both;
		}
		
		@include media(880px) {
			clear:right;
			float:right;
		}
		
		.menu-block-wrapper ul.menu.sm  {
			
			border:none;
			box-shadow:none;
					
			& > li {
				border-left:none;
				flex-grow: 1;
				text-align: center;

				&:last-child { 
					position:relative;
				}

				@include media(880px) {
					& > a > .sub-arrow {
						display:none;
					}
				}

				& > a {
					background-color: $nav-base-bg-color;
					text-align: center;
					font:$nav-base-font;
					color:$nav-base-text-color;
					padding:$nav-base-padding;
					text-transform: uppercase;
					border-bottom: $nav-base-border;

					@media (min-width:880px) and (max-width:1470px) {
						font: $nav-base-font-smaller;
						padding: $nav-base-padding-smaller;
					}

					&:hover {
						border-bottom: $nav-hover-border;
						padding:$nav-base-hover-padding;
						background-color:$nav-hover-bg-color;
						color:$nav-hover-text-color;

						@media (min-width:880px) and (max-width:1470px) {
							padding:$nav-base-hover-padding-smaller;
						}
					}	
					&.current,
					&.active.current.highlighted {
						background-color:$nav-active-bg-color;
						color:$nav-active-text-color;
						border-radius:$nav-active-border-radius;
						border-bottom: $nav-active-border;
					}

					&.current.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-radius:$nav-active-border-radius;
					}

					&.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-bottom: $nav-highlight-border;
						border-top: none;
						//padding:$nav-base-hover-padding;

						@media (min-width:880px) and (max-width:1150px) {
							//padding:$nav-base-hover-padding-smaller;
						}

						@media (min-width:880px) {
							//padding:$nav-base-hover-padding-smaller;
						}

						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
							//border-bottom: ;
						}
					}
				}
			}
			
////			& > li > ul.menu {
////				@include media(880px) {
////					margin-top: 1px !important;
////				} 
////			}
			
			li {
				a {
					cursor:pointer; 
					background-color: transparent;
															
					.sub-arrow {
						background:$arrow-grey-right no-repeat scroll center center transparent;
						background-size:contain;
						width: 16px;
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}
				
				ul.menu {	
					background:$nav-sub-bg-color;
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					box-shadow:$nav-sub-box-shadow;
					
					li {
						border-top:none; 
						
						a {
							color:$nav-sub-text-color;
							font: $nav-sub-base-font;
							line-height:1.225;
							padding:$nav-sub-base-padding;
							border-left: 2px solid transparent !important;
							
							&.current {
								background:$nav-sub-bg-color;
							}
							
							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}  
							
							&.active,
							&.active:hover {
								color:$nav-sub-active-text-color;
								border-left:$nav-sub-active-border !important;
							} 
							
							.sub-arrow {
								background:$arrow-grey-right no-repeat scroll center center transparent;
								background-size:contain;
								text-indent:-999em;
								overflow:hidden;
								right:10px;				
							}	
							
							&.active {
								.sub-arrow {
									background:$arrow-grey-right no-repeat scroll center center transparent;
									background-size:contain;
								}
							}
							
							&:hover, 
							&.active:hover  {
								.sub-arrow {
									background:$arrow-grey-right no-repeat scroll center center transparent;
									background-size:contain;
								}								
							}							
						}	
						
						&.expanded {
							
							& > a {
								&.highlighted { 
									background-color:$nav-sub-highlight-bg-color;
									color:$nav-sub-highlight-text-color;
									border-left:$nav-sub-highlight-border !important;

									.sub-arrow {
										background:$arrow-grey-right no-repeat scroll center center transparent;
										background-size:contain;
									}

									&:hover {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-sub-hover-text-color;
									}
									&.current.active,
									&.current.active:hover {
										//background-color:$nav-sub-hover-bg-color;
										color:$nav-sub-active-text-color;
										border-left:$nav-sub-active-border !important;
									}

									& + ul {			
										a {
											border-left:$nav-sub-highlight-border !important;

											&.active,
											&.active:hover {
												border-left:$nav-sub-active-border !important;
											} 
										}
									}
								}
								
								&.current,
								&.current:hover {
									border-left:$nav-sub-active-border !important;
								}
							}
						}
					}
				}
			}
			
		}	
		
////////////////////////////////////      MOBILE      ////////////////////////////////////
		@media (max-width:879px) {
		
			#main-menu-state:checked ~ .main-menu-btn { background:$nav-button-hover-bg-color;} //margin: 0 0 1px; height: 75px;}
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:$nav-button-bar-height; margin-top: -4px; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }


			.menu-block-wrapper ul.menu.sm {
				
				border: $nav-mob-border;
				border-top: none; 
				background-color: white;
				box-shadow: $nav-mob-shadow;				
				position: absolute;
				right: 0;
				width: calc(100% - 5%); 
				max-width: 400px;
				z-index: 1005;
				border-top: $nav-mob-border-top;
				
				@media (min-width:$narrow) and (max-width:879px) {
					top: 0;
				}
				
////				@media (min-width:$narrow) and (max-width:1030px) {
////					top: 0;
////				}
				
				& li ul.menu li.expanded>a.highlighted+ul a {
					border-left: none !important;
				}

				& > li {
					
					border: none;
					
					&.first.leaf {
						//border: none;
						//border-top:$nav-mob-expanded-border;
					}
					&.first.first {
						border: none;
						border-top:$nav-mob-expanded-border;
					}
					
					a .sub-arrow {
						width: 34px;
					}

					///////////////////////////////      1st LEVEL      ///////////////////////////////
					& > a {
						background-color:$nav-mob-1st-bg-color;
						text-align: left;
						font:$nav-base-font;
						color:$nav-sub-text-color;
						padding:$nav-sub-base-padding;
						text-transform: uppercase;
						border-bottom: $nav-mob-border;
						text-decoration: none;

						&:hover {
							//text-decoration: underline;
							//text-decoration-color: $princeton-orange;/
							background-color:$nav-sub-hover-bg-color;
							color:$nav-sub-hover-text-color;
							border-bottom: $nav-mob-border;
							.sub-arrow {
								background:$arrow-grey-down no-repeat scroll center center transparent;
								background-size:50%;
							}	
						}

						&.current { 
							background:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-mob-border;
							padding:$nav-sub-base-hover-padding;
							//border-bottom-width: 2px;
							//border-top:$nav-mob-expanded-border;
							.sub-arrow {
								background:$arrow-grey-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {						
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-grey-down no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						}

						&.active,
						&.active.active-trail,
						&.active.current.highlighted {
							//text-decoration: underline;
							background-color: $nav-mob-active-bg-color;
							color: $nav-mob-active-text-color;
							.sub-arrow {
								background:$arrow-grey-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {
								color: $nav-mob-active-text-color;
								
								.sub-arrow {
									background:$arrow-grey-down no-repeat scroll center center transparent;
									background-size:50%;
								} 
							}
						}

						&.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-highlight-border;
							padding:$nav-sub-base-hover-padding;
							//border-bottom-width: 2px;
							
//							// LEVEL BORDERS: TOP  //
//							border-top: 1px solid $nav-mob-1st-border-color;
							
							.sub-arrow {
								background:$arrow-grey-up no-repeat scroll center center transparent;
								background-size:50%;						
							}
							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-grey-up no-repeat scroll center center transparent;
									background-size:50%;
								}	
							}
						}

						&.active-trail {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							//border-bottom: $nav-mob-border;
							border-bottom: $nav-active-border;
							padding:$nav-sub-base-hover-padding;
							//border-bottom-width: 2px;

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}
						}

						&.current.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-active-border;
							padding:$nav-sub-base-hover-padding;
							//border-bottom-width: 2px;

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}
						}

						&.active.highlighted {
							
							.sub-arrow {
								background:$arrow-grey-up no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {
								.sub-arrow {
									background:$arrow-grey-up no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						} 
					}
				}
				
				li {
					border: none;
					
					a {
//						border-top: $nav-mob-border;
//						border-left: none !important;
						//padding-left: 0.875rem;
						
						color: $nav-mob-text-color;
						.sub-arrow {
							background:$arrow-grey-down no-repeat scroll center center transparent;
							background-size:50%;
							height: 46px;
							margin-top: -23px;
							//border-left: $nav-mob-border;
						}
					}
					
					
					///////////////////////////////      NEXT LEVELS      ///////////////////////////////
					
					/* ul BACKGROUNDS */
					&.expanded {
						
						// 2nd level //
						ul.menu {
							background-color: $nav-mob-2nd-bg-color;
							border-top: none; //1px solid $nav-mob-1st-border-color;	
//							
//							li.first a {
//								border-top:none;
//							}
//
//							li:not(.first) a {
//								border-top: 1px solid $nav-mob-2nd-line-color;
//							}
							
//							// LEVEL BORDERS: BOTTOM (TOP: Look 2nd level a.highlighted)  //
//							li.last {					
								border-bottom: none; //1px solid $nav-mob-1st-border-color;
//								margin-bottom: -1px;
//								z-index: 1;
//								position: relative;
//							}
							
							a {
								border-left: none !important;
								padding-left: 1.375rem;								

								&.active {
									background-color: $nav-mob-active-bg-color;
									color: $nav-mob-active-text-color;
									
									&:hover {
										background-color:$nav-sub-hover-bg-color;
									}
								}

								&.current,
								&.current:hover {
									border-bottom: none; 
									border-top: none;
									border-left: none !important;
								}
							}
							
							

							li.expanded {
						
								//border-bottom: $nav-mob-border;
							
								a {
									border-left: none !important;
									padding-left: 1.375rem;				
									
									&.active {
										background-color: $nav-mob-active-bg-color;
										color: $nav-mob-active-text-color;
										
										&:hover {
											background-color:$nav-hover-bg-color;
										}
									}

									&.current {
										border-bottom: none; 
										border-top: none;
									}

									&.highlighted { 
										border-left: none !important;
										border-bottom:$nav-sub-highlight-border !important;

										&.current.active,
										&.current.active:hover {
											border-left: none !important;
											border-bottom:$nav-sub-active-border !important;
										}

										& + ul {			
											a {
												border-left: none !important;

												&.active,
												&.active:hover {
													border-left: none !important;
													border-bottom:$nav-sub-active-border !important;
												}
											}
											
											li.leaf {
												a {
													&.active,
													&.active:hover {
														border-left: none !important;
														border-bottom: none !important;
													} 
												}
											}
										}
									}

									&.current {
										border-left: none !important;
										background-color: $nav-mob-active-bg-color;
									}
									
									&.current.active,
									&.current.active-trail{
										border-left: none !important;
										border-bottom:$nav-sub-active-border !important;
										background-color: $nav-mob-active-bg-color;
									}
									&.current:hover,
									&.current.active:hover {
										border-left: none !important;
										background-color: $nav-hover-bg-color;
									}
								}
								
								// 3rd level //
								ul.menu {	
									background-color: $nav-mob-3rd-bg-color;
//									border-top: 2px solid $nav-mob-2nd-border-color;	
//
//									li.first a {
//										border-top:none;
//									}
//
//									li:not(.first) a {
//										border-top: 1px solid $nav-mob-3rd-line-color;
//									}
							
//									// LEVEL BORDERS: BOTTOM (TOP: Look 3rd level a.highlighted)  //
//									li.last {					
//										border-bottom: 1px solid $nav-mob-2nd-border-color;
//										z-index: 2;
//									}
							
									a {
										border-left: none;
										padding-left: 1.875rem;								

										&.active {
											background-color: $nav-mob-active-bg-color;
											color: $nav-mob-active-text-color;						
										}
									}
								}

								li.expanded {
									
									// 4th level //
									ul.menu {	
										background-color: $nav-mob-4th-bg-color;	
//										border-top: 2px solid $nav-mob-3rd-border-color;	
//
//										li.first a {
//											border-top:none;
//										}
//
//										li:not(.first) a {
//											border-top: 1px solid $nav-mob-4th-line-color;
//										}
										
//										// LEVEL BORDERS: BOTTOM (TOP: Look 4th level a.highlighted)  //
//										li.last {				
//											border-bottom: 1px solid $nav-mob-3rd-border-color;
//											z-index: 3;											
//										}
							
										a {
											border-left: none;
											padding-left: 2.375rem;								

											&.active {
												background-color: $nav-mob-active-bg-color;
												color: $nav-mob-active-text-color;
											}
										}
									}
								}
							}
						}
					}
					
					/* ARROWS + HIGHLIGHT */ 
					ul.menu {	
						box-shadow: none;
						
						li {
							//border-top:$nav-mob-expanded-border;
							border-top: none;
							
							a {
								color: $nav-mob-text-color;
								.sub-arrow {
									background:$arrow-grey-down no-repeat scroll center center transparent;
									background-size:50%;
									right:0;
									height: 39px;
									margin-top: -19px;
									//border-left: $nav-mob-border;
								}	
															
								&.active {
									.sub-arrow {
										background:$arrow-grey-down no-repeat scroll center center transparent;
										background-size:50%;
									}
								}

								&:hover, 
								&.active:hover  {
									.sub-arrow {
										background:$arrow-grey-down no-repeat scroll center center transparent;
										background-size:50%;
									}								
								}
							}
							
							&.expanded {								
								ul.menu a.current {
									background-color: $nav-mob-2nd-highlight-bg-color;

									&.active {
										//background-color:$nav-sub-hover-bg-color;
										//color:$nav-hover-text-color;
										.sub-arrow {
											background:$arrow-grey-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}

									&:hover {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-grey-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}
								
								////////////**     2nd LEVEL     **////////////
								& > a.highlighted { 
									background-color:$nav-mob-2nd-highlight-bg-color;
									color:$nav-mob-2nd-highlight-text-color;
									
//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-2nd-border-color;

									.sub-arrow {
										background:$arrow-grey-up no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover {
										background-color:$nav-sub-hover-bg-color;	
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-grey-up no-repeat scroll center center transparent;
											background-size:50%;
										}									
									}
									
									&.current.active {
										color:$nav-hover-text-color;
										color:$nav-mob-active-text-color;

										.sub-arrow {
											background:$arrow-grey-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}

								////////////**     3rd LEVEL     **////////////
								& > ul.menu > li.expanded a.highlighted { 
									background-color: $nav-mob-3rd-highlight-bg-color;
									color: $nav-mob-3rd-highlight-text-color;
									
//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-3rd-border-color;

									.sub-arrow {
										background:$arrow-grey-up no-repeat scroll center center transparent;
										background-size:50%;
									}
									
									&:hover {
										background-color:$nav-sub-hover-bg-color;										
									}
									
									&:hover, 
									&.current.active {
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-grey-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}								
								}

//								////////////**     4th LEVEL     **////////////
//								& > ul.menu > li.expanded > ul.menu > li.expanded > a.highlighted { 
//									background-color: $nav-mob-4th-highlight-bg-color;
//									color: $nav-mob-4th-highlight-text-color;
//									
////									// LEVEL BORDERS: TOP  //
////									border-top: 1px solid $nav-mob-4th-border-color;
//
//									.sub-arrow {
//										background:$arrow-grey-up no-repeat scroll center center transparent;
//										background-size:50%;
//									}
//
//									&:hover, 
//									&.current.active {
//										background-color:$nav-sub-hover-bg-color;
//										color:$nav-hover-text-color;
//
//										.sub-arrow {
//											background:$arrow-grey-up no-repeat scroll center center transparent;
//											background-size:50%;
//										}
//									}								
//								}
							}
						}
					}
				}
			}
		}
	}
}