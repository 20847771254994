/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;

	.card-qa-answer {
		display: none;
		padding: 10px;	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		padding: 10px 0 10px 30px;
		line-height: 20px;
		color: $action-default;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand no-repeat scroll 0 50% / 16px 16px transparent;
		font: 400 1.222rem/1.3 $base-font-family;
		
		&:hover {
			color: $action-hover;
			background: $ico-expand-over no-repeat scroll 0 50% / 16px 16px transparent;
		}
		
		&.active {
			color: $action-default;
			background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;
			
			&:hover {
				color: $action-hover;
				background: $ico-collapse-over no-repeat scroll 0 50% / 16px 16px transparent;
			}		
		}
	}
}

.palette-alt-1,
.palette-alt-3 {
	.view-faqs { 
		.card-qa-question {
			color: $primary;
			background: $ico-expand no-repeat scroll 0 50% / 16px 16px transparent;

			&:hover {
			color: $action-hover;
			background: $ico-expand-over no-repeat scroll 0 50% / 16px 16px transparent;
			}

			&.active {
				color: $primary;
				background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;

				&:hover {
					color: $action-hover;
					background: $ico-collapse-over no-repeat scroll 0 50% / 16px 16px transparent;
				}		
			}
		}
	}
}
.palette-highlight,
.palette-alt-2 {
	.view-faqs { 
		.card-qa-question {
			color: $dark_imperial_blue;
			background: $ico-expand no-repeat scroll 0 50% / 16px 16px transparent;
			text-decoration: underline;

			&:hover {
			color: white;
			background: $ico-expand-white no-repeat scroll 0 50% / 16px 16px transparent;
			}

			&.active {
				color: $dark_imperial_blue;
				background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;

				&:hover {
					color: white;
					background: $ico-collapse-white no-repeat scroll 0 50% / 16px 16px transparent;
				}		
			}
		}
	}
}

