.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	} 
}

select {
	background: $arrow-grey-down no-repeat calc(100% - 8px) 50%/20px auto $form-bg-color;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	padding-right: 46px;	
	//background-color: $form-alt-bg-color;
}

header {
  select {
		background-color: $form-alt-bg-color;
	}
}

.form-item {
  .image-widget-data {
    margin-bottom: $vert-space/2;
  }
}

.views-exposed-form {
	select {
		background-color: $form-alt-bg-color;
	}
	.views-exposed-widget {
    min-height: 93px;
	}
}