// /* HEADER */ //

$header-menu-font 				: 0.778rem/1.36 $base-font-family;
$header-search-field-font       : 0.778rem/1.36 $base-font-family;

$header-bg-color                   : none;

$header-menu-link-color            : $primary;
$header-menu-link-hover            : $action-hover;

$header-search-field-bg-color      : $glitter;
$header-search-field-text-color    : $primary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;