//.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }
//.i18n-fr .site-title a { background:$logo-fr no-repeat scroll 0 0 transparent; }
//.i18n-pt .site-title a { background:$logo-pt no-repeat scroll 0 0 transparent; }

.site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
		&:after {
		content: " ";
		display: block;
		height: 1px;
		clear: both;
		background-color: $gainsboro;
		//border-bottom: 1px solid $gainsboro;
	}
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.header-outer-wrapper {
		position:relative;
		
		
		@media (max-width:879px) {
			display: flex;
    	flex-wrap: wrap;
			
			.region-header-wrapper {
				order: 1;
				width: 100%;
			}
			.header-inner-wrapper {
				order: 2;
			}
			.main-nav-wrapper {
				width: 100%;
    		position: absolute;
				//bottom: 0;
				bottom: -1px;
			}
		}
		
		@include outer-container($site-max-width);
	}

	.site-branding {
		margin-top:0;
		margin-left:1.125rem;
		position: relative;
		float: left;
		
		@include media(880px) {
			margin-top:2.15rem;
		}
		@include media($wide) {
			margin-top: 1.875rem;
		}
		.site-title {
			position:relative;

			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:170px;
				height:60px;
				background-position-y: 100%;
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}
	
	.wrapper-region-header {	
		display: flex;
		//flex-direction: row;
		
		//@include media(880px) {
			flex-direction: row-reverse;
		//}
		
		.block-search {
			//float:right;
			order: 1;
			//height:10rem;
			@include media(880px) {
				height:auto;
			}
			h2 {
				font-family:$base-font-family;
				font-size:1rem;
				color:$primary;
				float:left;
				line-height:1.5;
				margin-top:1rem;
				margin-right:0.75rem;
				cursor:pointer;
				display:none;
				@include media(880px) {
					margin-right:2.5rem;
				}
			}
			.form-wrapper {
				display:none;
				@include media(880px) {
					display:block;
				}
			}
			.form-item-search-block-form {
				margin-top:0.75rem;
				margin-right:0.75rem;
				margin-bottom:0;
				@include media(880px) {
					margin-bottom:1rem;
				}
				float:left;
				input.form-text {
					width:12.5rem;
					height:2.375rem;
					background-color:$header-search-field-bg-color;
					color:$header-search-field-text-color;
					box-shadow:$header-search-field-box-shadow;
					border:$header-search-field-border;
					border-radius:$header-search-field-border-radius;
					font:$header-search-field-font;
					padding:0.375rem 0.75rem;
					height: 38px;
					width: 180px;
    			min-height: 38px;
					margin-bottom: 0;
				}
			}
			.form-actions {
				float:left;

				input.form-submit {
					background: transparent $search no-repeat scroll 50% 50% / 14px;
					width:1rem;
					height:1rem;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.25rem;
					border: none;

					&:hover {
						background: transparent $search-hover no-repeat scroll 50% 50% / 14px;
						border: none;
					}
				}
			}
		}

		.block-gtranslate {
			display: block;
			order: 2;
			
			margin:0.75rem 0.75rem 1rem 0.75rem;
			
			@include media(880px) {
				margin-right:0.75rem;
			}

			::-ms-expand {
				display: none;
			}

			select {
				font:$header-search-field-font;
				height: 38px;
				width: 160px;
				
				@include media(880px) {
					width: 180px;
				}
				margin-bottom: 0;
			}
		}

		.block-system.block-menu {
			order: 3;
      display: none;
      & > h2 {
        display: none;
      }
      @include media(540px) {
        display: block;
      }
		}

		.block-menu {
			order: 4;
			ul {
				margin-bottom: 0;
        padding-left: 0.5rem;
				
				li {
					display:inline-block;
					margin:0 0.75rem 0 0;
				}
			}

			a {
				font:$header-menu-font;
				color:$header-menu-link-color;

				&:hover {
					color:$header-menu-link-hover;
				}
			}
		}
    
    
		.block-menu:not(.block-system) {
			ul {				
				li {
					@include media(880px) {
						&.last {
							display: none;
						}
					}
				}
			}
		}
    
		.block-locale {
			clear:both;
			display:none;
		}
	}
}