
@mixin title-underline-default() {
	content: "";
	display: block;
	height: 1px;
	width: 40px;
	margin: 15px 0 15px;
	background: $princeton_orange;
}

@mixin title-underline-date-default() {
	content: "";
	display: block;
	height: 1px;
	width: 40px;
	margin: 11px 0 0 0;
	background: $ocean_blue_boat;
}

@mixin title-underline-default-hover() {
	background: $dollar_bill;
}

@mixin title-underline-highlight() {
	@include title-underline-date-default;
	background: white;
}

@mixin title-underline-alt-1() {
	@include title-underline-default;	
	background: white;
}

@mixin title-underline-alt-2() {
	@include title-underline-date-default;
	background: white;
}

@mixin title-underline-date-alt-3() {
	@include title-underline-date-default;
	background: white;
}

@mixin node-title-underline-default() {
	@include title-underline-date-default;
}

@mixin title-underline-alt-1-hover() {
	background: white;
}

@mixin card-feed-link-underline-default() {
	@include title-underline-default;
	width: 100%;
	height: 2px;
	margin-top: 5px;	
	background: $ocean_blue_boat;
}

@mixin card-feed-link-underline-default-hover() {
	width: 100%;
	height: 2px;
	margin-top: 5px;
	background: $dollar-bill;
}

@mixin card-feed-link-underline-highlight() {
	@include title-underline-default;
	width: 100%;
	height: 2px;
	margin-top: 5px;	
	background: white;
}

@mixin card-feed-link-underline-highlight-hover() {
	width: 100%;
	height: 2px;
	margin-top: 5px;
	background: $secondary;
}

// ATTACHMENTS

@mixin attachment-underline-default() {
	content: "";
	display: block; //inline-block;
	height: 2px;
	width: 40px;
	margin: 15px 0 8px;
}
 
@mixin attachment-underline-default-hover() {
	background: $dollar_bill;
}

@mixin attachment-underline-alt() {
	@include attachment-underline-default;
	background: $davys_grey;
}

@mixin attachment-underline-alt-hover() {
	background: white;
}
