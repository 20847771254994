.section-row.section-with-promo {
	padding: 0 0 20px;
	@include media($normal) {
		padding: 0;
	}
	background-color: transparent !important;
	position: relative;
	min-height: 380px;
	display: flex;
	width: 100%;
	margin: 20px 0 0;
	min-height: 400px;
  align-items: center;
	
	.section-inner {
    overflow: visible;
   	padding: 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
    
		//display: table-cell;
		//vertical-align: middle;
		
		.view-promo {
			@include media($normal) {
				background: $action-default;
			}

			.promo {
				max-width: 100%; //1600px;
    		margin-left: auto;
    		margin-right: auto;
				text-align: center;

				.card-image {
					width: 260px;
					height: 260px;
					border-radius: 50%;
					overflow: hidden;
					padding: 0;
					//border: 4px solid white;
					display: inline-block;
					
					@include media($normal) {
						position: absolute;
						width: 380px;
						height: 380px;
						top: 0;
						left: 5%;
					}
					
					@media (min-width:1400px) {
						left: calc(50% - 630px);
					}
				}

				a.card-promo-text {
					padding: 150px 10% 40px;
					background-color: $action-default;
					background-image: $promo-grey-top, $pattern-white, $promo-grey-bottom;
					background-position: 50% -35px, 50% 50px, 50% calc(100% + 45px);
    			background-size: 1480px, calc(100% - 40px), 1480px;
					background-repeat: repeat-x, no-repeat, repeat-x;	
					display: block;
   			 	color: white;
					text-align: left;					
					margin-top: -130px;
					
					@media (min-width:840px) {
						background-size: 1480px, 800px, 1480px;
					}
					
					@include media($normal) {
						padding: 50px 5% 50px calc(5% + 480px);
						margin-top: 0px;
   					min-height: 270px;
						
						background-position: 50% -35px, 20px 50px, 50% calc(100% + 45px);						
					}
					
					@media (min-width:1400px) {
						//background-position: 50% -35px, calc(50% - 280px) 50px, 50% calc(100% + 45px);
						padding: 50px 0 50px calc(50% - 140px);
					}
					
					@media (min-width:1600px) {
						background-position: 50% -35px, calc(50% - 340px) 50px, 50% calc(100% + 45px);
					}
					
					&:hover {					
						background-color: $action-hover;
						transition: background  300ms linear 0s;
					}

					.promo-title {
						font-size: 1.667rem;
						//font-weight: 600;
						line-height: 1.2;
    				margin-bottom: 1rem;
					}

					.promo-description p {
						font-size: 1rem;
						margin: 0;
						font-weight: normal;
						padding-right: 45px;
						display: inline;
						//background: $long-arrow-white-right 100% 50% no-repeat;
					}
					
					@media (min-width:1400px) {
					//@include media($normal) {
						.card-title-field, .card-description {
							max-width: 765px;
							//margin-left: calc(50% - 530px);
						}
					}
				}
			}
		}
	}
}