/*ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: $vert-space/2;
    padding-left: $horz-space;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $vert-space/2;
    padding-left: $horz-space;
  }
}*/

ul, ol {
	margin-bottom: $vert-space;
	padding-left: $horz-space*1.25;
}

ul {
	list-style-type: disc;
}
ol {
	list-style-type: decimal;
}

.item-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
	
	li {
		margin-left:0;
		padding-left:0;
	}
}
dl {
  margin-bottom: $vert-space/2;

  dt {
    font-weight: bold;
    margin-top: $vert-space/2;
  }

  dd {
    margin: 0;
  }
}

.card-body,
.card-description,
.card-description-et,
.faq-question {
	ol,
	ul {
		margin-bottom:$vert-space;
		padding-left: $horz-space*1.25;
		
		&.larger-text {
			padding-left: 2rem;
		}
	}
	ol {
		list-style:outside decimal;
		
		li {
			padding-left:10px;
		}
	}
	ul {
		list-style:outside disc;
	}
	p + ol,
	p + ul {
		margin-top:-10px;
	}
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top:30px;
	}
	
}

