// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : $azureish_white; //rgba(255, 255, 255, 0.7) ;
$slide-text-color          : $primary;
$slide-text-font           : 1.222rem/1.25 $title-font-family;

$slide-sidebar-text-bg-color       : white; //rgba(255, 255, 255, 0.7) ;

$slide-arrow-size: 40px auto;
$slick-arrow-size: 30px auto;


// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: transparent $arrow-blue-left no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-default-prev-hover-bg      				: transparent $arrow-green-left no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-default-next-bg            				: transparent $arrow-blue-right no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-default-next-hover-bg      				: transparent $arrow-green-right no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-default-dot-border         				: 2px solid $davys_grey;
$slick-default-dot-hover-border    				: 2px solid $dollar_bill;
$slick-default-dot-hover-bg      					: $dollar_bill;
$slick-default-dot-active-border    			: 2px solid $princeton_orange;
$slick-default-dot-active-bg-color 				: $princeton_orange;
$slick-default-dot-active-hover-bg-color 	: $princeton_orange;


$slick-alt-1-nav-bg                				: transparent; // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: transparent $arrow-white-left no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-alt-1-prev-hover-bg         				: transparent $arrow-white-left-fill no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-alt-1-next-bg               				: transparent $arrow-white-right no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-alt-1-next-hover-bg         				: transparent $arrow-white-right-fill no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-alt-1-dot-border    								: 2px solid white;
$slick-alt-1-dot-hover-border      				: 2px solid white;
$slick-alt-1-dot-hover-bg      						: white;
$slick-alt-1-dot-active-border      			: 2px solid $davys_grey;
$slick-alt-1-dot-active-bg-color   				: $davys_grey;
$slick-alt-1-dot-active-hover-bg-color 		: $davys_grey;


$slick-alt-2-nav-bg                 			: red; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: transparent $arrow-white-left no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-alt-2-prev-hover-bg         				: transparent $arrow-white-left no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-alt-2-next-bg               				: transparent $arrow-white-right no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-alt-2-next-hover-bg         				: transparent $arrow-white-right no-repeat scroll 50% 50% / $slick-arrow-size;
$slick-alt-2-dot-border    								: 2px solid $faded_blue;
$slick-alt-2-dot-hover-border      				: 2px solid $white;
$slick-alt-2-dot-hover-bg				      		: white;
$slick-alt-2-dot-active-border     				: 2px solid $faded_blue;
$slick-alt-2-dot-active-bg-color   				: red;
$slick-alt-2-dot-active-hover-bg-color 		: red;


