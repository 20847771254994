@import url(../fonts/fonts.css);

$base-font-family      		: 'Futura LT W01 Medium', sans-serif;
$title-font-family    		: 'Gill Sans W01 Medium', serif;
$subtitle-font-family 	 	: 'Gill Sans W01 Medium',serif;

$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$button-link-font 				: $base-font-size-normal/1 $title-font-family;

/* FORMS */
$button-font 							: 400 $base-font-size/1 $title-font-family;