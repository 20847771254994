.view-gsa-members {
  .section-inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    position: relative;

    .view-content {
      order: 4;

      .views-row {
        .card.card-with-image {
          display: flex;
          flex-direction: row;
          background: none;

          .card-photo {
            align-self: flex-start;
            width: 30%;
            max-width: 160px;
            min-width: 100px;

            @media (max-width: 719px) and (min-width: 540px) {
              min-width: 80px;
            }
          }

          .card-text {
            margin-top: 20px;
            margin-right: 0;
            margin-left: 0;
            align-self: flex-start;
            width: 100%;
            background-color: white;

            .member-name {
              margin-bottom: $vert-space/4;

              div {
                display: inline;
              }
            }

            .member-job {
              font-size: 0.889rem;
              margin-bottom: $vert-space/2;

              .card-job-title {
                font-size: 1rem;
                color: $secondary;
              }

              .card-institution {
                color: gray;
                font-size: 0.889rem;
              }
            }

            .label {
              color: $primary;
              margin-right: $horz-space/4;
              display: inline-block;
              font-size: 0.889rem;

              & + p,
              & + a {
                margin-bottom: 5px;
                font-size: 0.889rem;
              }
            }

            .member-taxonomies {
              li,
              .card-vetvaxhub-country {
                font-size: 0.889rem;
                text-transform: uppercase;
                display: inline-block;
                border: 1px solid red;
                padding: 0px 5px;
                margin: 0 3px 5px 0;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .attachment {
      order: 3;
      margin: $vert-space*1.5 0 $vert-space*2 0;

      .view-display-id-a_z_filter {
        padding: 0;

        .section-inner {
          padding: 0;

          span.views-summary a {
            padding: 8px 15px;
            background: $button-link-default-bg;
            border: 2px solid $button-link-default-border-color;
            color: $button-link-default-color;
            font-size: 1rem;
            line-height: 50px;

            &:link, &:visited {
              background: $button-link-default-bg;
              border-color: $button-link-default-border-color;
              color: $button-link-default-color;
            }

            &:after {
              background: $button-link-default-icon;
            }

            &:hover {
              background: $button-link-default-hover-bg;
              border-color: $button-link-default-hover-border-color;
              color: $button-link-default-hover-color;

              &:after {
                background: $button-link-default-hover-icon;
              }
            }
          }
        }
      }
    }

    .view-filters {
      order: 1;

      .views-exposed-widget {
        width: 100%;

        @include media($narrow) {
          width: 50%;

          input,
          select {
            min-height: 52px;
          }
        }

        @include media($normal) {
          //width: calc(50% - 246px/2);
          width: 50%;
          &:nth-child(even) {
            padding-right: 0;
          }
          input {
            min-height: 52px;
          }
        }

        select {
          width: 100%;
        }
      }

      .views-exposed-widget-submit {
        width: 100%;

        .views-exposed-widget {
          width: auto;
        }

        @include media($normal) {
          clear: left;
          margin-top: 32px;

          .views-exposed-widget {
            min-height: auto;
            margin: 0;
          }
        }
      }

      .views-exposed-widget {
        display: inline-block !important;
      }
    }

    .view-header {
      order: 2;

      .result-count {
        padding: 30px 0 0;
        font-size: 1rem;
      }
    }
  }
}

fieldset#edit-captcha {
  display: none;
}
