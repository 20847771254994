.item-list  ul.pager {
	position: relative;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		color: $secondary;
		font-weight: 300;
		padding:0.25em 0.5em;
		
		&:hover {
			background:none;
			color:$dollar_bill;
		}
	}
	li.pager-item.pager-next {
		float: right;
		position: absolute;
		right: 55px;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			display: block;
			padding-right: 20px;
			background-position: 100% 50%;
			background-size: 8px;
		}
	}
	li.pager-item.pager-last {
		float: right;
		a {
			display: block;
			padding-right: 20px;
			background-position: 100% 50%;
			background-size: 14px;
		}
	}
	li.pager-item.pager-previous {
		float: left;
		position: absolute;
		left: 55px;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			display: block;
			padding-left: 20px;
			background-position: 0% 50%;
			background-size: 8px;
		}
	}
	li.pager-item.pager-first {
		float: left;
		a {
			display: block;
			padding-left: 20px;
			background-position: 0% 50%;
			background-size: 14px;
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0;
		padding: 0;
	}
	li.pager-current {
		background: none;
		color:$princeton_orange;
		padding:0.25em 0.5em;
		margin-top: 0.25em;
		font-weight: 300;
	}

	li {
		&.pager-next a {
			background:$pager-default-next-bg;
			&:hover {
				background:$pager-default-next-hover-bg;
			}
		}
		&.pager-previous a {
			background:$pager-default-prev-bg;
			&:hover {
				background:$pager-default-prev-hover-bg;
			}
		}
		&.pager-first a {
			background:$pager-default-first-bg;
			&:hover {
				background:$pager-default-first-hover-bg;
			}
		}
		&.pager-last a {
			background:$pager-default-last-bg;
			&:hover {
				background:$pager-default-last-hover-bg;
			}
		}
		&.pager-next,
		&.pager-previous,
		&.pager-first,
		&.pager-last {
			a {
				display: block;
				text-indent: -9999px;
			}
		}
		&.pager-first,
		&.pager-last {
			a {
				background-size: 40px;
				&:hover {
					background-size: 40px;
				}
			}
		}
		&.pager-next,
		&.pager-previous {
			a {
				background-size: 30px;
				&:hover {
					background-size: 30px;
				}
			}
		}
	}
}
