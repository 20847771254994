.section-inner:empty {
	display: none !important;
}
main .section-row {
	&:first-child {
		margin-top: 0;
		padding-top: 0;
	}
	@include media ($narrow) {
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	@include palette-default-slick-controls;
	@include palette-default-button-link;

	.card { 
		@include palette-default-cards;
	}
	a.card:hover {
		@include card-hover;
	}
	.card-more-link {
		@include palette-default-card-more-link;
	}		
	.card-feed-link {
		@include palette-default-card-feed-link;
	}

	&.row-max-1, &.row-max-2 {
		a.card {
			background: none;
		}
		.card-text.card-with-summary {
			background-color: white;
			margin-top: 30px;
		}
	}
}

.postscript-first .section-row .section-inner {
	@include outer-container($site-max-width);
}

.postscript-first .section-row,
.content-hero .section-row {
	 padding:$section-row-padding;
	 
	 &.row-max-1, &.row-max-2 {
		a.card {
			background: none;
		}
		.card-text.card-with-summary {
			background-color: white;
			margin-top: 30px;
		}
	}

	 &.section-with-slider {
		 padding:0;
	 }
	
	h2 {
		font:$section-row-title-font;
		.card-title {
			font-size: 1.8888rem;
			&:after{
				@include title-underline-date-default;
				margin-top: 5px;
				margin-bottom: 20px;
			}
		}
	}

	/* PALETTE DEFAULT */
	&.palette-default {
		background-color:$palette-default-bg-color;
		color:$palette-default-text-color;
		@include palette-default-button-link;
		@include palette-default-slick-controls;
	
		h2 {
			color:$palette-default-title-color;
		}
		& > h2 .card-title span {
			background-color: $palette-default-bg-color;
		}
		
		a {
			color:$palette-default-link-color;

			&:hover {
				color:$palette-default-link-hover;
			}
		}
		
		.card { 
			@include palette-default-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-default-card-more-link;
		}		
		.card-feed-link {
			@include palette-default-card-feed-link;
		}

	}

	/* PALETTE ALT-1 */
	&.palette-alt-1 {
		background-color:$palette-alt-1-bg-color;
		color:$palette-alt-1-text-color;
		@include palette-alt-1-button-link;
		@include palette-alt-1-slick-controls;
		
		h2 {
			color:$palette-alt-1-title-color;
		}
		& > h2 .card-title span {
			background-color: $palette-alt-1-bg-color;
		}
		
		a {
			color:$palette-alt-1-link-color;

			&:hover {
				color:$palette-alt-1-link-hover;
			}
		}
		
		.card { 
			@include palette-alt-1-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-alt-1-card-more-link;
		}		
		.card-feed-link {
			@include palette-alt-1-card-feed-link;
		}		
	}

	/* PALETTE ALT-2 */
	&.palette-alt-2 {
		background: $palette-alt-2-bg-color $pattern-white 20px 0/600px no-repeat;
		
		@media (min-width:1600px) {
			background-position: calc(50% - 480px) 0;
		}
		
		color:$palette-alt-2-text-color;
		@include palette-alt-2-button-link;
		@include palette-alt-2-slick-controls;
		
		h2 {
			color:$palette-alt-2-title-color;
			
			.card-title {
				&:after {
					@include title-underline-alt-2;
					margin-top: 5px;
					margin-bottom: 20px;
				}
			}
		}
		& > h2 .card-title span {
			background-color: $palette-alt-2-bg-color;
		}
		
		a {
			color:$palette-alt-2-link-color;

			&:hover {
				color:$palette-alt-2-link-hover;
			}
		}
		
		.card { 
			@include palette-alt-2-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-alt-2-card-more-link;
		}		
		.card-feed-link {
			@include palette-alt-2-card-feed-link;
		}		
	}

	/* PALETTE ALT-3 */
	&.palette-alt-3 {
		background-color:$palette-alt-3-bg-color;
		color:$palette-alt-3-text-color;
		@include palette-alt-3-button-link;
		@include palette-alt-3-slick-controls;
		
		h2 {
			color:$palette-alt-3-title-color;
		}
		& > h2 .card-title span {
			background-color: $palette-alt-3-bg-color;
		}
		
		a {
			color:$palette-alt-3-link-color;

			&:hover {
				color:$palette-alt-3-link-hover;
			}
		}
		.card { 
			@include palette-alt-3-cards;
		}
		a.card:hover {
			@include card-hover-alt-3;
		}
		.card-more-link {
			@include palette-alt-3-card-more-link;
		}		
		.card-feed-link {
			@include palette-alt-3-card-feed-link;
		}	
	}

		/* PALETTE HIGHLIGHT */
	&.palette-highlight {
		background: $palette-highlight-bg-color $pattern-white 20px 0/600px no-repeat;
		
		@media (min-width:1600px) {
			background-position: calc(50% - 480px) 0;
		}
		
		color:$palette-highlight-text-color;
		@include palette-highlight-button-link;
		@include palette-highlight-slick-controls;
		
		h2 {
			color:$palette-highlight-title-color;
			.card-title {
				&:after {
					@include title-underline-alt-2;
					margin-top: 5px;
					margin-bottom: 20px;
				}
			}
		}
		& > h2 .card-title span {
			background-color: $palette-highlight-bg-color;
		}
		
		a {
			color:$palette-highlight-link-color;

			&:hover {
				color:$palette-highlight-link-hover;
			}
		}
		
		.card { 
			@include palette-highlight-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-highlight-card-more-link;
		}		
		.card-feed-link {
			@include palette-highlight-card-feed-link;
		}

		.card-tagged-view h3, p {
			a {
				text-decoration: $pallette-higlight-link-text-decoration;
			}
		}

		.view-faqs {
			.card-qa-question {
				text-decoration: underline;
				color: $dark_imperial_blue;
				display: inline-block;
				font-weight: normal;
				&:hover {
					color: white;
				}
			}
		}
	}
}


// HOME //
.front .section-row > .section-inner > h2 {
	//text-align: center;
	font-size: 1.778rem;
  
  .card-title {
    display: inline-block;
  }
}

.front .section-row > .section-inner > .card-body h2 {
	text-align: center;
	font-weight: 400;
	
	@include media ($narrow) {
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
}
