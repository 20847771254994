// /* MAIN MENU */ //

$nav-base-font        	 	: 1.222rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.3 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: transparent;
$nav-base-bg-color        		: white; 
$nav-base-text-color     		 	: $primary;
$nav-base-padding        			: 0.625rem 1.5625rem;
$nav-base-hover-padding   		: 0.625rem 1.5625rem;
$nav-sub-base-padding					: 0.625rem 0.875rem;
$nav-sub-base-hover-padding		: 0.625rem 0.875rem;


$nav-base-padding-smaller 				: 0.375rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.375rem 1.125rem;

$nav-base-border                : 4px solid $peach_puff;

$nav-active-bg-color      		: white;
$nav-active-text-color    		: $primary;
$nav-active-border-radius 		: 0;
$nav-active-border            :  4px solid $princeton_orange;

$nav-hover-bg-color       		: white;
$nav-hover-text-color     		: $dollar_bill;
$nav-hover-border	  	    		: 4px solid $dollar_bill;

$nav-highlight-bg-color   		: white;
$nav-highlight-text-color 		: $primary; 
$nav-highlight-border	    		: 4px solid $dollar_bill;

$nav-sub-bg-color         		: $pale_grey;
$nav-sub-text-color       		: $primary;

$nav-sub-hover-bg-color   		: white;
$nav-sub-hover-text-color   	: $dollar_bill;

$nav-sub-active-bg-color      		: $pale_grey;
$nav-sub-active-text-color    		: $princeton_orange;
$nav-sub-active-border					 	: 2px solid $princeton_orange;

$nav-sub-highlight-bg-color   		: white;
$nav-sub-highlight-text-color 		: $primary;
$nav-sub-highlight-border  				: 2px solid $dollar_bill;

$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : 0; 


$nav-mob-text-color      						: $primary;
$nav-mob-active-text-color      		: $princeton_orange;
$nav-mob-active-bg-color      			: initial;
$nav-mob-active-border					 		: none;

$nav-mob-highlight-color					 	: $dollar_bill;
$nav-mob-active-color					 			: $princeton_orange;

$nav-mob-1st-bg-color     					: white;
$nav-mob-1st-highlight-bg-color     : white;
$nav-mob-1st-highlight-text-color   : $primary;
$nav-mob-1st-line-color     				: transparent;
$nav-mob-1st-border-color     			: $gainsboro;

$nav-mob-2nd-bg-color     					: $glitter;
$nav-mob-2nd-highlight-bg-color     : $glitter;
$nav-mob-2nd-highlight-text-color   : $primary;
$nav-mob-2nd-line-color     				: transparent;
$nav-mob-2nd-border-color     			: transparent;

$nav-mob-3rd-bg-color     					: $azureish_white;
$nav-mob-3rd-highlight-bg-color     : $azureish_white;
$nav-mob-3rd-highlight-text-color   : $primary;
$nav-mob-3rd-line-color     				: transparent;
$nav-mob-3rd-border-color     			: transparent;

$nav-mob-4th-bg-color     					: $azureish_white;
$nav-mob-4th-highlight-bg-color     : $azureish_white;
$nav-mob-4th-highlight-text-color   : $primary;
$nav-mob-4th-line-color     				: transparent;
$nav-mob-4th-border-color     			: transparent;

$nav-mob-border											: 1px solid $gainsboro;
$nav-mob-expanded-border						: 1px solid $gainsboro;
$nav-mob-border-top									: 4px solid $gainsboro;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: $grey;
$nav-button-hover-bg-color			: $dollar_bill;
$nav-button-height      				: 70px;
$nav-button-width      					: 70px;
$nav-button-bar-bg-color      	: $grey;
$nav-button-bar-hover-bg-color  : white;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 46px;
$nav-button-bar-border-radius 	: 8px;
