// /* FOOTER */ //
$footer-first-bg           : $secondary;
$footer-first-text         : white;
$footer-first-action       : $dollar_bill;
$footer-first-action-hover : $baby_blue_eyes;
$footer-first-border       : none;

//
// --- Set the card style for FOOTER-FIRST by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-first-cards {
	@include card-light();
}
@mixin palette-footer-first-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-first-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-first-slick-controls {
	@include slick-controls-alt-2();
}


$footer-second-bg           : $black;
$footer-second-text         : white;
$footer-second-action       : white;
$footer-second-action-hover : $pale_grey;

//
// --- Set the card style for FOOTER-SECOND by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-second-cards {
	@include card-light();
}
@mixin palette-footer-second-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-second-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-second-slick-controls {
	@include slick-controls-alt-2();
}

// /* FOOTER CARD-LINKS  -- Follow . social media icons */
$card-links-action-bg            : white;
$card-links-border-radius        : 40px;
$card-links-width                : 40px;
$card-links-height               : 40px;
$card-links-hover-opacity        : 1;
$card-links-hover-border-radius  : 40px;
$card-links-hover-bg             : $dollar_bill;


$footer-third-bg            : white;
$footer-third-text          : $primary;
$footer-third-action        : $primary;
$footer-third-action-hover  : $action-hover;