// /* PAGERS */ //
$pager-default-prev-bg             				: $arrow-blue-left no-repeat scroll 50% 50%;
$pager-default-prev-hover-bg      				: $arrow-blue-left no-repeat scroll 50% 50%;
$pager-default-next-bg            				: $arrow-blue-right no-repeat scroll 50% 50%;
$pager-default-next-hover-bg      				: $arrow-blue-right no-repeat scroll 50% 50%;

$pager-default-first-bg             			: $arrow-blue-double-left no-repeat scroll 50% 50%;
$pager-default-first-hover-bg      				: $arrow-blue-double-left no-repeat scroll 50% 50%;
$pager-default-last-bg            				: $arrow-blue-double-right no-repeat scroll 50% 50%;
$pager-default-last-hover-bg      				: $arrow-blue-double-right no-repeat scroll 50% 50%;
