$button-bg           : #81D2C7 !default;
$button-text-color   : white !default;
$button-border-color : transparent !default;

@mixin button-states($button-bg, $button-text, $button-border-color) {
	background: $button-bg;
	border-color:$button-border-color;
	color: $button-text;
	display: inline-block;
	transition: all 0.25s ease-in-out;
	
	&:link, &:visited {
		background: $button-bg;
		border-color:$button-border-color;
		color: $button-text;
	}
	
	&:hover {
		background: darken($button-bg,10%);
		border-color: darken($button-border-color,10%);
		color: $button-text;
	}
}


// /* BUTTON LINKS */
@mixin button-link-default() {
	a.button,
	.button a {
		background:$button-link-default-bg;
		border: 2px solid $button-link-default-border-color;
		color: $button-link-default-color;
		&:link, &:visited {
			background:$button-link-default-bg;
			border-color: $button-link-default-border-color;
			color: $button-link-default-color;
		}
		&:after {
			background: $button-link-default-icon;
		}
		&:hover {
			background:$button-link-default-hover-bg;
			border-color: $button-link-default-hover-border-color;
			color: $button-link-default-hover-color;
			&:after {
				background: $button-link-default-hover-icon;
			}
		}
	}
}

@mixin button-link-highlight() {
	a.button,
	.button a {
		text-decoration: none;
		background:$button-link-highlight-bg;
		border: 2px solid $button-link-highlight-border-color;
		color: $button-link-highlight-color;
		&:link, &:visited {
			background:$button-link-highlight-bg;
			border-color: $button-link-highlight-border-color;
			color: $button-link-highlight-color;
		}
		&:after {
			background: $button-link-highlight-icon;
		}
		&:hover {
			background:$button-link-highlight-hover-bg;
			border-color: $button-link-highlight-hover-border-color;
			color: $button-link-highlight-hover-color;
			&:after {
				background: $button-link-highlight-hover-icon;
			}
		}
	}
}

@mixin button-link-alt() {
	a.button,
	.button a {
		background:$button-link-alt-bg;
		border: 2px solid $button-link-alt-border-color;
		color: $button-link-alt-color;
		&:link, &:visited {
			background:$button-link-alt-bg;
			border-color: $button-link-alt-border-color;
			color: $button-link-alt-color;
		}
		&:after {
			background: $button-link-alt-icon;
		}
		&:hover {
			background:$button-link-alt-hover-bg;
			border-color: $button-link-alt-hover-border-color;
			color: $button-link-alt-hover-color;
			&:after {
				background: $button-link-alt-hover-icon;
			}
		}
	}
}

@mixin button-link-alt1() {
	a.button,
	.button a {
		background:$button-link-alt1-bg;
		border: 2px solid $button-link-alt1-border-color;
		color: $button-link-alt1-color;
		&:link, &:visited {
			background:$button-link-alt1-bg;
			border-color: $button-link-alt1-border-color;
			color: $button-link-alt1-color;
		}
		&:after {
			background: $button-link-alt1-icon;
		}
		&:hover {
			background:$button-link-alt1-hover-bg;
			border-color: $button-link-alt1-hover-border-color;
			color: $button-link-alt1-hover-color;
			&:after {
				background: $button-link-alt1-hover-icon;
			}
		}
	}
}

@mixin button-link-alt2() {
	a.button,
	.button a {
		background:$button-link-alt2-bg;
		border: 2px solid $button-link-alt2-border-color;
		color: $button-link-alt-color;
		&:link, &:visited {
			background:$button-link-alt2-bg;
			border-color: $button-link-alt2-border-color;
			color: $button-link-alt2-color;
		}
		&:after {
			background: $button-link-alt2-icon;
		}
		&:hover {
			background:$button-link-alt2-hover-bg;
			border-color: $button-link-alt2-hover-border-color;
			color: $button-link-alt2-hover-color;
			&:after {
				background: $button-link-alt2-hover-icon;
			}
		}
	}
}

@mixin button-link-alt3() {
	a.button,
	.button a {
		background:$button-link-alt2-bg;
		border: 2px solid $button-link-alt2-border-color;
		color: $button-link-alt2-color;
		&:link, &:visited {
			background:$button-link-alt2-bg;
			border-color: $button-link-alt2-border-color;
			color: $button-link-alt2-color;
		}
		&:after {
			background: $button-link-alt2-icon;
		}
		&:hover {
			background:$button-link-alt2-hover-bg;
			border-color: $button-link-alt2-hover-border-color;
			color: $button-link-alt2-hover-color;
			&:after {
				background: $button-link-alt2-hover-icon;
			}
		}
	}
}

@mixin button-link-alt-old() {
	a.button,
	.button a {
		background:$button-link-alt-bg;
		border-color: $button-link-alt-border-color;
		color: $button-link-alt-color;
		&:link, &:visited {
			background:$button-link-alt-bg;
			border-color: $button-link-alt-border-color;
			color: $button-link-alt-color;
		}		
		&:after {
			background: $button-link-alt-icon;
		}
		&:hover {
			background:$button-link-alt-hover-bg;
			border-color: $button-link-alt-hover-border-color;
			color: $button-link-alt-hover-color;
			&:after {
				background: $button-link-alt-hover-icon;
			}
		}
	}
}


// /* FORUM BUTTON LINKS */
@mixin forum-button-link-default() {
  background:$button-link-default-bg;
  border: 2px solid $button-link-default-border-color;
  color: $button-link-default-color;
  &:link, &:visited {
    background:$button-link-default-bg;
    border-color: $button-link-default-border-color;
    color: $button-link-default-color;
  }
  &:after {
    background: $button-link-default-icon;
  }
  &:hover {
    background:$button-link-default-hover-bg;
    border-color: $button-link-default-hover-border-color;
    color: $button-link-default-hover-color;
    &:after {
      background: $button-link-default-hover-icon;
    }
  }
}

@mixin forum-button-link-alt() {
  background:$button-forum-link-default-bg;
  border: 2px solid $button-forum-link-default-border-color;
  color: $button-forum-link-default-color;
  text-transform: capitalize;
  &:link, &:visited {
    background:$button-forum-link-default-bg;
    border-color: $button-forum-link-default-border-color;
    color: $button-forum-link-default-color;
  }
  &:after {
    background: $button-forum-link-default-icon;
  }
  &:hover {
    background:$button-forum-link-default-hover-bg;
    border-color: $button-forum-link-default-hover-border-color;
    color: $button-forum-link-default-hover-color;
    &:after {
      background: $button-forum-link-default-hover-icon;
    }
  }
}