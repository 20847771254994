 main {
	 #sidebar-second {
		background: $sidebar-second-bg;
		margin: $sidebar-second-margin;
		padding: $sidebar-second-padding;
	}

	.side-row {
		background: $side-row-bg;
		border: $side-row-border;
		border-radius: $side-row-border-radius;
		box-shadow: $side-row-box-shadow;
		margin: $side-row-margin;
		padding:$side-row-padding;
		overflow: hidden;

		@include palette-sidebar-slick-controls;
		@include palette-sidebar-button-link;

		 &.section-with-slider {
			 padding:0;
		 }

		.section-inner {
			padding: $side-row-inner-padding;
			@media (max-width:$narrow) {
				padding: $side-row-inner-mobile-padding;
			}		
		}

		h2 {
			font:$side-row-title-font;
			.card-title {
				font-size: inherit;
			}
		}
		
		.section-inner > .card-title > h2,
		&.block h2 {
//			margin: -20px 0 0 -20px;
//			padding: 20px;
//			width: calc(100% + 40px);
//			margin-bottom: 20px;
//			border-bottom: none;
		}
		
		.view-header .side-row {
//			margin: 0;
//			padding: 0;
//			overflow: visible;
		}

		.card {
			@include palette-sidebar-cards;

			border: $card-sidebar-border;
			border-radius: $card-sidebar-border-radius;
			box-shadow: $card-sidebar-shadow ;
			margin-bottom: $card-sidebar-margin-bottom;

			.card-text {
				padding: $card-text-padding;
				&.card-imageless {
					padding-top: 10px;
				}
				
				
				.card-title {
					margin-bottom: 0;
					padding-bottom: 0;
					background-color: transparent;
					h2 {
						margin: 0;
						&:after {
							@include title-underline-default;
							background: $ocean_blue_boat;
							margin: 5px 0 0 0;
						}
					}
				}
			}
		}
		
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-sidebar-card-more-link;
		}
		.card-feed-link {
			@include palette-sidebar-card-feed-link;
		}
		.card-title {
			margin: -20px;
			margin-bottom: 20px;
			padding: 20px;
			background-color: $azureish_white;
			h2 {
				margin: 0;
				&:after {
					@include title-underline-default;
					background: $ocean_blue_boat;
					margin: 5px 0 0 0;
				}
			}
		}
	}
} 