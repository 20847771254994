// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		   : $title-font-family;
$card-title-font        	   : 300 1.222rem/1.2727 $card-title-font-family;
$card-font-family       	   : $base-font-family;
$card-more-link-font		   : normal 1.333rem/1.5 $title-font-family;
$card-feed-link-font		   : normal 1rem/1.2222 $base-font-family;


$card-shadow                   : $box-shadow;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : $baby_blue_eyes;
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $secondary;
$card-date-font                : 0.8888rem/1.25 $base-font-family;


// /* --- HOVER STYLES: SAME FOR MOST CARDS */
$card-hover-bg-color           : white;
$card-hover-border             : none;
$card-hover-shadow             : $box-shadow;
$card-action-hover             : $dollar_bill; //colour of card title text when you hover over it
$card-text-hover-color         : $secondary; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $secondary;
$card-date-hover-bg-color      : $baby_blue_eyes; // allows display dates (e.g. events) to have different hover background from the rest of the card

// /* --- HOVER STYLES: ALT3 */
$card-alt3-hover-bg-color           : $spanish_orange;
$card-alt3-hover-border             : none;
$card-alt3-hover-shadow             : $box-shadow;
$card-alt3-action-hover             : white; //colour of card title text when you hover over it
$card-alt3-text-hover-color         : $secondary; //colour of regular card text when you hover over it
$card-alt3-date-hover-text-color    : $secondary;
$card-alt3-date-hover-bg-color      : white; // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $secondary;
$card-light-action-default     : $black;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $secondary;
$card-light-subtitle-color     : $secondary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $pale_grey;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $black;
$card-medium-action-default    : $black;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $secondary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $glitter;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $black;
$card-dark-action-default      : $black;
$card-dark-action-hover        : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : $secondary;
$card-dark-subtitle-color      : $secondary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $pearl_aqua;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : $pale_grey;
$card-highlight-action-default : $grey;
$card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : $white;
$card-highlight-subtitle-color : $pale_grey;

// /* --- CARD-ALT3 */
$card-alt3-bg-color       : $princeton_orange;
$card-alt3-border-color   : transparent;
$card-alt3-title-color    : $secondary;
$card-alt3-action-default : $grey;
$card-alt3-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-alt3-text-color     : $secondary;
$card-alt3-subtitle-color : $pale_grey;

// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : white;
$card-more-link-default-color       : $secondary;
$card-more-link-default-icon        : $arrow-grey-right no-repeat 100% 100% / 14px auto;
$card-more-link-default-hover-bg    : white;
$card-more-link-default-hover-color : $card-action-hover;
$card-more-link-default-hover-icon  : $arrow-green-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-bg              : $grey;
$card-more-link-alt-color           : white;
$card-more-link-alt-icon            : $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg        : $action-hover;
$card-more-link-alt-hover-color     : $secondary;
$card-more-link-alt-hover-icon      : $arrow-white-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-3-bg            : $princeton_orange;
$card-more-link-alt-3-color         : $primary;
$card-more-link-alt-3-icon          : $arrow-grey-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-3-hover-bg      : $spanish_orange;
$card-more-link-alt-3-hover-color   : white;
$card-more-link-alt-3-hover-icon    : $arrow-white-right no-repeat 100% 100% / 14px auto;

// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : none; // background for the entire link
$card-feed-link-default-color       : $action-default;
$card-feed-link-default-icon        : $arrow-blue-right no-repeat scroll 50% 50% / 12px auto; //background for the default :after component
$card-feed-link-default-hover-bg    : none;
$card-feed-link-default-hover-color : $action-hover;
$card-feed-link-default-hover-icon  : $arrow-green-right no-repeat scroll 50% 50% / 12px auto;

$card-feed-link-highlight-bg          : none; // background for the entire link
$card-feed-link-highlight-color       : white;
$card-feed-link-highlight-icon        : $arrow-white-right no-repeat scroll 50% 50% / 12px auto; //background for the default :after component
$card-feed-link-highlight-hover-bg    : none;
$card-feed-link-highlight-hover-color : $secondary;
$card-feed-link-highlight-hover-icon  : $arrow-grey-right no-repeat scroll 50% 50% / 12px auto;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : none;
$button-link-default-border-color       : $action-default;
$button-link-default-color              : $action-default;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $dollar_bill;
$button-link-default-hover-border-color : $dollar_bill;
$button-link-default-hover-color        : $primary;
$button-link-default-hover-icon         : none;

$button-link-highlight-bg                     : none;
$button-link-highlight-border-color           : white;
$button-link-highlight-color                  : white;
$button-link-highlight-icon                   : none;
$button-link-highlight-hover-bg               : white;
$button-link-highlight-hover-border-color     : white;
$button-link-highlight-hover-color            : $secondary;
$button-link-highlight-hover-icon             : none;

$button-link-alt-bg                     : none;
$button-link-alt-border-color           : white;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : white;
$button-link-alt-hover-border-color     : white;
$button-link-alt-hover-color            : $secondary;
$button-link-alt-hover-icon             : none;

$button-link-alt1-bg                     : none;
$button-link-alt1-border-color           : $action-default;
$button-link-alt1-color                  : $action-default;
$button-link-alt1-icon                   : none;
$button-link-alt1-hover-bg               : $dollar_bill;
$button-link-alt1-hover-border-color     : $dollar_bill;
$button-link-alt1-hover-color            : $primary;
$button-link-alt1-hover-icon             : none;

$button-link-alt2-bg                     : none;
$button-link-alt2-border-color           : white;
$button-link-alt2-color                  : white;
$button-link-alt2-icon                   : none;
$button-link-alt2-hover-bg               : white;
$button-link-alt2-hover-border-color     : white;
$button-link-alt2-hover-color            : $secondary;
$button-link-alt2-hover-icon             : none;

$button-forum-link-default-bg                 : none;
$button-forum-link-default-border-color       : $glitter;
$button-forum-link-default-color              : $secondary;
$button-forum-link-default-icon               : none;
$button-forum-link-default-hover-bg           : $dollar_bill;
$button-forum-link-default-hover-border-color : $dollar_bill;
$button-forum-link-default-hover-color        : $primary;
$button-forum-link-default-hover-icon         : none;
